













import { defineComponent, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { canSetMarketingCookies } from '@/inc/utils'

import PodcastHero from '@/components/podcast/Hero.vue'
import MagazineStories from '@/components/magazine/Stories.vue'

export default defineComponent({
  name: 'Podcast',
  components: {
    PodcastHero,
    MagazineStories,
  },
  props: {},

  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const cookieValue = content.value.utm_cookie

    const setCookie = () => {
      if (!cookieValue) {
        return
      }

      // Set expire time
      const now = new Date()
      const expireTime = now.setMonth(now.getMonth() + 6)

      ctx.root.$cookie.set('CAMPAIGN', cookieValue, {
        expires: new Date(expireTime),
        path: '/',
      })
    }

    onMounted(() => {
      if (canSetMarketingCookies) {
        setCookie()
      }
    })

    return {
      content,
    }
  },
})
